<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-flow-outline"
      title="Change Orders"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="change_orders"
        disable-pagination
        >

        <template v-slot:[`item.change_order`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="view_change_order(item)"
            >
              mdi-pencil
            </v-icon>

        <v-dialog
            v-model="dialog"
            max-width="50%"
            :retain-focus="false"
          >
          <v-form
          ref="form"
          >
            <v-card>
                <v-card-title>
                  <span class="card-title font-weight-light">Change Order</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                   
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected_change_order.HomeOwnerName"
                            label="Homeowner Name"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected_change_order.Title"
                            label="Change Title"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-textarea
                            v-model="selected_change_order.HomeOwnerComment"
                            label="Homeowner comments"
                            :rules="commentRules"
                            disabled
                          ></v-textarea>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-textarea
                            v-model="selected_change_order.StaffComment"
                            label="Additional Notes From Boulder staff (Attached to Email)"
                            :rules="commentRules"
                          ></v-textarea>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                        <v-autocomplete
                          :items="vendors"
                          label="Vendor"
                          item-text="Email"
                          item-value="VendorEmail"
                          menu-props="auto"
                          class="px-3 py-3"
                          :rules="vendorRules"
                          v-model="selected_change_order.VendorEmail"
                        ></v-autocomplete>

                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected_change_order.Cost"
                            label="Cost"
                            type="number"
                            :counter="4"
                            :rules="costRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="approve"
                    :disabled="!valid"
                  >
                    Approve
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="decline"
                    :disabled="!valid"
                  >
                    Decline
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
            </v-card>
          </v-form>

        </v-dialog>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>


<script>
import axios from 'axios';

export default {
    name: 'StaffChangeOrders',
    data(){
      return {
        dialog: false,
        valid: false,
        //TODO
        permissions:localStorage.getItem('permissions'),
        staff_email: 'steve@skelleymarketing.com',
        boulder_email : 'steve@skelleymarketing.com',
        commentRules: [
          v => !!v || 'Comments are required',
          ],
        costRules: [
          v => !!v || 'Cost is required',
          v => (v<=9999) || 'Max 4 characters.',
          ],
        vendorRules: [
          v => !!v || 'Vendor is required'
          ],
        headers: [
            {
            text: 'Homeowner Name',
            sortable: false,
            class:"primary--text",
            value: 'HomeOwnerName',
            },
            { 
            text: 'Change Order Type', 
            value: 'Title', 
            sortable: false,
            class:"primary--text",
            },
            { 
            text: 'Status', 
            value: 'StatusIdTemp', 
            align: 'end',
            sortable: false,
            class:"primary--text",
            },
            { 
            text: 'Approve/Decline', 
            value: 'change_order', 
            align: 'end',
            sortable: false,
            class:"primary--text",
            }
        ],
        change_orders: [],
        selected_change_order: {
            HomeOwnerId:'',
            HomeOwnerName: '',
            HomeownerEmail:'',
            VendorEmail:'',
            VendorId:'',
            OrderId:'',
            Title: '',
            HomeOwnerComment: '',
            StaffComment: '',
            Cost:'',
            AgentEmail:'',
        },
        default_change_order: {
            HomeOwnerId:'',
            HomeOwnerName: '',
            HomeownerEmail:'',
            VendorEmail:'',
            VendorId:'',
            OrderId:'',
            Title: '',
            HomeOwnerComment: '',
            StaffComment: '',
            Cost:'',
            AgentEmail:'',
        },
        vendors: []
      }
    },

    created(){
      this.initializeChangeOrders()
      this.initializeEmailVariables()
      this.getVendorEmail()
    },

    methods: {
      initializeEmailVariables(){
        this.acceptStatus= (this.permissions === 'create:users,fullaccess:staff')  ? "ApprovedByStaff" : "ApprovedByHomeowner"
        this.declineStatus=(this.permissions === 'create:users,fullaccess:staff') ? "DeclinedByStaff" : "DeclinedByHomeowner"
        this.declineEmailTemplate= (this.permissions === 'create:users,fullaccess:staff') ? "change_order_staff_declined_notification" : "change_order_homeowner_declined_notification"
      },
      initializeChangeOrders(){
        axios
        .get(process.env.VUE_APP_GET_CHANGEORDERS_URL)
        .then(response => {
          this.change_orders = response.data
          this.change_orders.forEach((change_order) => {
              const {StatusId} = change_order
              change_order.StatusIdTemp = this.change_order_status_mapper(StatusId)
          })
              console.log("🚀 ~ file: ChangeOrders.vue ~ line 253 ~ this.change_orders.forEach ~ this.change_orders", this.change_orders)
        })
        .catch(error => {
          console.log(error)
        })
      },
      getVendorEmail(){
        axios
        .get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL, {})
        .then(response => {
            const { data } = response
            this.vendors = data
        })
        .catch(error => {
            console.log(error)
        })
      },
      view_change_order (change_order) {
        this.selected_change_order = Object.assign({}, change_order)
        console.log("🚀 ~ file: ChangeOrders.vue ~ line 272 ~ view_change_order ~ this.selected_change_order", this.selected_change_order)
        this.dialog = true
        this.valid = true
      },
      close(){
        // this.selected_change_order = Object.assign({}, this.default_change_order)
        this.dialog = false
        this.valid = false
      },
      approve(){
       const {HomeOwnerName, HomeOwnerComment,HomeownerEmail} = this.selected_change_order
        var body = {
          "vendor_email": this.selected_change_order.VendorEmail || 'Vendor-TBD',
          "owner_comment":this.selected_change_order.HomeOwnerComment || 'No home owner comment',
          "staff_comment":this.selected_change_order.StaffComment || 'No staff comment',
          "cost": parseInt(this.selected_change_order.Cost) || 0,
          "status": 2,
          "order_id": this.selected_change_order.OrderId,
          HomeOwnerName,
          HomeOwnerComment,
          HomeownerEmail
        }
        axios
        .post(process.env.VUE_APP_UPDATE_CHANGEORDER_URL,body)
        .then(response => {
          console.log(response.data)
          this.sendEmail([this.homeowner_email,this.staff_email,this.selected_change_order.AgentEmail],this.staff_email,"change_order_staff_approved_notification",body,this.change_order_title)
          this.selected_change_order = Object.assign({}, this.default_change_order)
          this.initializeChangeOrders()
          this.dialog = false
        })
        .catch(error => {
          console.log(error)
        })
      },
      decline(){
        const {HomeOwnerName, HomeOwnerComment,HomeownerEmail} = this.selected_change_order
        var body = {
          "vendor_email": this.selected_change_order.VendorEmail || 'Vendor-TBD',
          "owner_comment":this.selected_change_order.HomeOwnerComment || 'No home owner comment',
          "staff_comment":this.selected_change_order.StaffComment || 'No staff comment',
          "cost": parseInt(this.selected_change_order.Cost) || 0,
          "status": 3,
          "order_id": this.selected_change_order.OrderId,
          HomeOwnerName,
          HomeOwnerComment,
          HomeownerEmail
        }
        axios
        .post(process.env.VUE_APP_UPDATE_CHANGEORDER_URL,body)
        .then(response => {
          console.log(response.data)
          this.sendEmail([this.homeowner_email,this.staff_email,this.selected_change_order.AgentEmail],this.staff_email,"change_order_staff_declined_notification",body,this.change_order_title)
          this.selected_change_order = Object.assign({}, this.default_change_order)
          this.initializeChangeOrders()
          this.dialog = false
        })
        .catch(error => {
          console.log(error)
        })
      },
      sendEmail(to,from,template,content,title){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "title" : title
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      },
      change_order_status_mapper(StatusID){

        if(StatusID === null){
          return 'No Status'
        }
        if(StatusID === 3){
          return 'Declined'
        }
        else if(StatusID === 2){
          return 'Approved'
        }
        if(StatusID){
          return 'Pending'
        }
      }
    },
  computed: {
      change_order_title(){
        return this.selected_change_order.Title
      },
      homeowner_email(){
        return this.selected_change_order.HomeownerEmail
      },
  }

}
</script>

<style scoped>

</style>